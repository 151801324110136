import { default as React, useState, useEffect } from "react"
import Layout from "../components/layout"
import _ from "lodash"

import { getOrganizations } from "../utilities/organizations"
import { getListsByOrganizationId } from "../utilities/lists"
import { sendBroadcast } from "../utilities/broadcasts"

const Lists = () => {
  const [lists, setLists] = useState([])
  const [selectedListID, setSelectedListID] = useState(null)
  const [message, setMessage] = useState("")

  const [isAfterSuccessfulSend, setIsAfterSuccessfulSend] = useState(false)

  async function updateOrganizations() {
    const organizations = await getOrganizations()
    const firstOrganization = organizations[0]
    const organizationLists = await getListsByOrganizationId(
      firstOrganization._id
    )

    setLists(organizationLists)
    setSelectedListID(organizationLists[0]._id)
  }

  useEffect(() => {
    updateOrganizations()
  }, [])

  const messageIsTooLong = message.length > 160
  const messageIsEmpty = message.trim().length === 0

  return (
    <Layout title="Broadcasts">
      <div className="Broadcasts">
        {isAfterSuccessfulSend && <h1>Broadcast sent successfully.</h1>}
        {!isAfterSuccessfulSend && (
          <React.Fragment>
            <h1>New Broadcast</h1>
            <h3>Send to</h3>
            <select
              value={selectedListID ? selectedListID : ""}
              onChange={e => {
                setSelectedListID(e.target.value)
              }}
            >
              {lists.map(list => {
                return (
                  <option value={list._id} key={list._id}>
                    {list.name}
                  </option>
                )
              })}
            </select>
            <div className="compose-bar">
              <h3>Compose message</h3>
              <h4 className={messageIsTooLong ? "danger" : ""}>
                {parseInt(message.length / 160) + 1} message(s)
              </h4>
            </div>
            <textarea
              value={message}
              onChange={e => {
                setMessage(e.target.value)
              }}
            ></textarea>
            <button
              disabled={messageIsEmpty}
              onClick={async () => {
                try {
                  await sendBroadcast({
                    listId: selectedListID,
                    message,
                  })
                  setIsAfterSuccessfulSend(true)
                } catch (e) {
                  alert(e)
                }
              }}
            >
              Send Broadcast
            </button>
          </React.Fragment>
        )}
      </div>
    </Layout>
  )
}

export default Lists
