// /broadcast/send
import { apiURL } from "./endpoints"
import _ from "lodash"

export async function sendBroadcast({ listId, message }) {
  return new Promise(async (resolve, reject) => {
    const url = apiURL + `/broadcast/send`
    const response = await fetch(url, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        list: listId,
        message,
      }),
    })
    if (!response.ok) {
      return reject(Error(response.statusText))
    }

    return resolve()
  })
}
